export function defaultTableSort<T>(
    sortA: T,
    sortB: T,
    isAsc: boolean
): number {
  return (sortA < sortB ? -1 : 1) * (isAsc ? 1 : -1);
}

export const trimOptionalParams = (params: {}): {} => {
  const httpParams = {};

  Object.keys(params).forEach((param): void => {
    if (params[param as keyof {}]) {
      httpParams[param as keyof {}] = params[param as keyof {}];
    }
  });

  return httpParams;
}

export const isUUID = (uuid: string): boolean => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(uuid);
}

/**
 * Takes an array element and returns an object with that element as a key and true as the value
 * example: [el] => { [el]: true }
 * @param array
 */
export const toBooleanObject = <T extends string[]>(array: T): { [x: string]: boolean } => {
  const obj: { [x: string]: boolean } = {};
  array.forEach(function (str) {
    obj[str] = true;
  });
  return obj;
}

export class Helpers {

  public static saveOrOpenBlob(blob: Blob, fileName: string): void {
    // Create a link to add a custom file name when downloading
    const element = document.createElement('a');
    element.href = URL.createObjectURL(blob);
    element.download = fileName; // get filename from header provided from backend
    document.body.appendChild(element);
    element.click();
  }

}
