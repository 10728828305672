import { createAction, props } from '@ngrx/store';
import { RegistrationsQueryParamsState, RegistrationsType } from '@registrations/overview-v2/state/reducer';

export const updatePagination = createAction(
  '[Registrations] Update overview pagination',
  props<{ pagination: Pick<RegistrationsQueryParamsState, '_page' | '_pageSize'> }>()
);

export const updateFilters = createAction(
  '[Registrations] Update overview filters',
  props<{ filters: Pick<RegistrationsQueryParamsState, 'insuranceBroker' | 'insuranceCompany' | 'dateFrom' | 'dateTo' | 'vehicleType' | 'status'> }>()
)

export const updateSorting = createAction(
  '[Registrations] Update overview sorting',
  props<{ sorting: Pick<RegistrationsQueryParamsState, '_sortBy' | '_sortDirection'> }>()
);

export const updateSearch = createAction(
  '[Registrations] Update overview search bar filter',
  props<{ searchTerm: string }>()
);

export const updateStateType = createAction(
  '[Registrations] Update overview state status (active/inactive)',
  props<{ registrationsType: RegistrationsType }>()
)
