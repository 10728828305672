import { environment } from '@environments/environment';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { RsSentry } from '@lib/utils/rs-senty-init';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { provideStore } from '@ngrx/store';
import { CoreModule } from '@app/core/core.module';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { routes } from '@app/app.routes';

new RsSentry({
  dsn: 'https://34ee7c4c329447a8a16fac7b7a2a28cd@o268277.ingest.sentry.io/1813332',
  enabled: environment.enableSentryMonitoring,
  environment: environment.environment,
  sentryReleaseName: environment.sentryReleaseName
}).sentryInit();

Sentry.getCurrentScope().setTag('app.name', 'ORD');
Sentry.getCurrentScope().setTag('app.environment', environment.environment);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
      withComponentInputBinding(),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
        paramsInheritanceStrategy: 'always',
      }),
    ),
    provideStore(),
    importProvidersFrom(CoreModule),
  ],
}).catch(error => console.error(error));
