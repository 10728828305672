/* eslint-disable @typescript-eslint/explicit-function-return-type, id-length */
import { importProvidersFrom, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Routes } from '@angular/router';
import { RsNumberGuard } from '@shared/guards/rs-number-guard/rs-number.guard';
import { RegistrationGuard } from '@shared/guards/registration/registration.guard';
import { DistributionCenterAccessGuard } from '@shared/guards/distribution-center/distribution-center-access-guard.service';
import { AdministrationGuard } from '@shared/guards/administration/administration.guard';
import { WallBoxGuard } from '@shared/guards/wall-box/wall-box.guard';
import { OrderGuard } from '@shared/guards/order/order.guard';
import { AdminGuard } from '@shared/guards/admin/admin.guard';
import { StoreModule } from '@ngrx/store';
import { catchError, finalize } from 'rxjs/operators';
import { RegistrationDetails } from '@app/registrations/details-section/shared/models/registration-details';
import { RsLoaderService, RsMessagesHandlerService } from '@lib/rs-ngx';
import { RegistrationV2Service } from '@app/registrations/details-section/shared/services/registration-v2.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { FrontPlateGuard } from '@shared/guards/front-plate/front-plate.guard';
import { PackageService } from '@distribution-center/packages/shared/services/package.service';
import { ItemService } from '@distribution-center/shared/services/item.service';
import { ProductService } from '@app/administration/services/product/product.service';
import { AdministrationService } from '@app/administration/services/administration.service';
import { initialState, metaReducers, overviewRegistrationsKey, registrationsReducer } from '@registrations/overview-v2/state/reducer';

export const getExistingRegistrationResolver = (
  hideRsLoaderAtResolverLevel?: boolean
): ResolveFn<RegistrationDetails> => (
  route: ActivatedRouteSnapshot
) => {
  const
    rsMessageHandler = inject(RsMessagesHandlerService),
    rsLoaderService = inject(RsLoaderService);

  rsLoaderService.show();

  return inject(RegistrationV2Service)
    .getRegistrationDetails(route.paramMap.get('registrationId')!)
    .pipe(
      catchError((httpError: HttpErrorResponse) => {
        rsMessageHandler.showBeErrorMsg({ httpError })
        return throwError(() => httpError);
      }),
      finalize(() => hideRsLoaderAtResolverLevel && rsLoaderService.hide())
    );
};

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'orders/active-orders-overview'
  },
  {
    path: 'rs-number',
    pathMatch: 'full',
    loadComponent: () => import('./rs-number-selection/rs-number-selection.component').then(module => module.RsNumberSelectionComponent)
  },
  {
    path: '',
    canActivate: [RsNumberGuard],
    children: [
      {
        path: 'orders',
        loadChildren: () => import('@orders/orders.routes')
          .then(module => module.ORDER_ROUTES),
        canActivate: [OrderGuard]
      },
      {
        path: 'registrations',
        canActivate: [RegistrationGuard],
        loadChildren: () => import('@registrations/registrations.routes')
          .then( routes => routes.REGISTRATIONS_ROUTES ),
        providers: [
          importProvidersFrom(
            StoreModule.forFeature(
              overviewRegistrationsKey,
              registrationsReducer,
              { initialState, metaReducers }
            )
          )
        ],
      },
      {
        path: 'front-plates',
        loadChildren: () => import('./front-plates/front-plates.module')
          .then(module => module.FRONT_PLATE_ROUTES),
        canActivate: [FrontPlateGuard]
      },
      {
        path: 'ev-products',
        loadChildren: () => import('@ev-products/ev-products.routes')
          .then(module => module.EV_PRODUCT_ORDER_ROUTES),
        canActivate: [WallBoxGuard]
      },
      {
        path: 'distribution-center',
        loadChildren: () => import('@distribution-center/distribution-center.routes')
          .then(module => module.DISTRIBUTION_CENTER_ROUTES),
        providers: [ItemService, PackageService],
        canActivate: [DistributionCenterAccessGuard]
      },
      {
        path: 'administration',
        loadChildren: () => import('./administration/administration.routes')
          .then(module => module.ADMINISTRATION_ROUTES),
        providers: [AdministrationService, ProductService],
        canActivate: [AdministrationGuard]
      },
      {
        path: 'admin',
        loadChildren: () => import('@admin/admin.routes')
          .then((module) => module.ADMIN_ROUTES),
        canActivate: [AdminGuard]
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'rs-number'
  }
];
