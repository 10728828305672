import { ActionReducer, createReducer, on } from '@ngrx/store';
import { updateFilters, updatePagination, updateSearch, updateSorting, updateStateType } from '@registrations/overview-v2/state/actions';
import { produce } from 'immer';
import { SortDirection } from '@angular/material/sort';
import { localStorageSync } from 'ngrx-store-localstorage';
import { rsLocalStorageMergeReducer } from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';

export const overviewRegistrationsKey = 'overviewRegistrations';

export enum RegistrationsType {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface RegistrationsQueryParamsState {
  _page: number;
  _pageSize: number;
  _sortBy: string;
  _sortDirection: SortDirection;
  insuranceCompany: number | null;
  insuranceBroker: number | null;
  searchTerm: string;
  vehicleType: 'CAR' | 'BIKE';
  status: string[];
  dateFrom: string | null;
  dateTo: string | null;
}

export interface RegistrationsTypeState {
  [registrationType: string]: RegistrationsQueryParamsState;
}

export type RegistrationsState = {
  filters: RegistrationsTypeState & { selectedType: RegistrationsType }
}

export const initialRegistrationsState = Object.keys(RegistrationsType).reduce(( acc, val ) => {
  acc[RegistrationsType[val]] = {
    _page: 1,
    _pageSize: 10,
    _sortBy: '',
    searchTerm: '',
    _sortDirection: '',
    insuranceBroker: null,
    insuranceCompany: null,
    vehicleType: '',
    dateFrom: null,
    dateTo: null,
    status: []
  };
  return acc;
}, {});


export const initialState: RegistrationsState = {
  filters: {
    selectedType: RegistrationsType.ACTIVE as never,
    ...initialRegistrationsState
  }
};

export const registrationsReducer = createReducer(
  initialState,
  on(updatePagination, ( state, { pagination } ) =>
    produce(state, draft => {
      Object.assign(draft.filters[state.filters.selectedType], pagination);
    })),
  on(updateSorting, ( state, { sorting } ) =>
    produce(state, draft => {
      Object.assign(draft.filters[state.filters.selectedType], sorting);
    })),
  on(updateSearch, ( state, { searchTerm } ) =>
    produce(state, draft => {
      draft.filters[state.filters.selectedType].searchTerm = searchTerm;
      draft.filters[state.filters.selectedType]._page = 1;
    })),
  on(updateStateType, ( state, { registrationsType } ) =>
    produce(state, draft => {
      draft.filters.selectedType = registrationsType;
    })),
  on(updateFilters, ( state, { filters } ) =>
    produce(state, draft => {
      Object.assign(draft.filters[state.filters.selectedType], filters);
      draft.filters[state.filters.selectedType]._page = 1;
    }))
);

export function localStorageSyncReducer( reducer: ActionReducer<RegistrationsState> ): ActionReducer<RegistrationsState> {
  return localStorageSync({
    keys: ['filters'],
    storageKeySerializer: ( key ): string => 'ord_persistent_registrationsV2_overview_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer
  })(reducer);
}

export const metaReducers = [localStorageSyncReducer];

