import version from 'environments-version.json';
import { OrdEnvironment } from '@environments/ord-environment';

const isOrdCloud = window.location.hostname.includes('ui.ord-cloud');
const isOrdLegacy = window.location.hostname.includes('ui.ord-legacy');
const environmentShort = 'tst';

export const environment: OrdEnvironment = {
  production: false,
  apiUrl: `https://api.ord.${environmentShort}.rentasolutions.org/ord/webapi`,
  preOrdApi: `https://api.pre-ord.${environmentShort}.rentasolutions.org`,
  distributionCenterApi: `https://api.distribution-center.${environmentShort}.rentasolutions.org`,
  apiGatewayUrl: `https://api-gateway.${environmentShort}.rentasolutions.org`,
  useMockedServices: false,
  enableSentryMonitoring: true,
  sentryReleaseName: 'test-ord-ui-12954',
  environment: 'test',
  authUrl: `https://authentication.${environmentShort}.rentasolutions.org`,
  topBarCdn: `https://cdn.rentasolutions.org/topbar/${environmentShort}/v1.3/`,
  topBarEnvironment: environmentShort,
  weblateEnvironment: environmentShort,
  version: version?.test,
  assetsEnvironment: 'test',
  amplitudeApiKey: '87de7b0e52b473a112b4d56719b4eb9f',
};

if (isOrdCloud) {
  Object.assign(environment, {
    apiUrl: `https://api.ord-cloud.${environmentShort}.rentasolutions.org/ord/webapi`
  });
}

if (isOrdLegacy) {
  Object.assign(environment, {
    apiUrl: `https://api-legacy.ord.${environmentShort}.rentasolutions.org/ord/webapi`
  });
}
