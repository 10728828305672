import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { userReducer, UserState } from './/user/user.reducer';
import { Keys, localStorageSync } from 'ngrx-store-localstorage';
import { registrationsReducer, RegistrationsState } from '@app/core/state/registrations/registrations.reducer';
import { rsLocalStorageMergeReducer } from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';

export interface CoreState {
  user: UserState,
  registrations: RegistrationsState,
}

export const coreReducer: ActionReducerMap<CoreState> = {
  user: userReducer as ActionReducer<UserState>,
  registrations: registrationsReducer as ActionReducer<RegistrationsState>,
};

const persistentReducerKeys = [
  {
    registrations: [
      'backToOverviewLink',
      'retrieveRegistrationData'
    ]
  },
  'user'
] as Keys;

export function localStorageSyncReducer(reducer: ActionReducer<CoreState>): ActionReducer<CoreState> {
  return localStorageSync({
    keys: persistentReducerKeys,
    storageKeySerializer: (key): string => 'ord_persistent_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer
  })(reducer);
}

export const metaReducers: MetaReducer<CoreState>[] = [localStorageSyncReducer];
